import React from 'react';

interface Props extends React.HTMLProps<HTMLInputElement> {
  onFileSelect: (file: File) => void;
  children: React.ReactChild | React.ReactChild[];
  id: string;
  className?: string;
}

export default function FileInput({
  onFileSelect,
  children,
  id,
  className,
  ...props
}: Props): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>();

  const onChange = (event: React.ChangeEvent) => {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files && fileInput.files[0];
    if (!file) {
      return;
    }
    onFileSelect(file);
    inputRef.current.value = ''; // Reset input value
  };

  return (
    <>
      <label htmlFor={id} className={className} tabIndex={0}>
        {children}
      </label>
      <input
        id={id}
        type="file"
        onChange={onChange}
        style={{ display: 'none' }}
        ref={inputRef}
        {...props}
      />
    </>
  );
}
