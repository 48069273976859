/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import cn from 'classnames';
import { useState, useRef, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

import styles from './ImageEditor.module.scss';
import 'react-image-crop/dist/ReactCrop.css';
import { useMediaQuery } from 'common/utils';
import { useEffect } from 'react';

export type Props = {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  mediaUrl: string;
  onEditComplete: (file: File) => void;
  fixedRatio?: number;
  setMediaUrl?: (url: string) => void;
  freeCrop?: boolean;
};

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const verticalAspect = 9 / 16;
const horizontalAspect = 16 / 9;
const squareAspect = 1 / 1;
export default function ImageEditor({
  open = false,
  setOpen,
  mediaUrl,
  onEditComplete,
  fixedRatio,
  freeCrop = false,
}: Props): JSX.Element {
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const croppedImage = useRef(null);
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const [aspect, setAspect] = useState<number | undefined>(
    freeCrop ? null : fixedRatio || horizontalAspect
  );
  const [processing, setProcessing] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const onLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      imgRef.current = e.currentTarget;
      const aspect = freeCrop
        ? horizontalAspect
        : fixedRatio || horizontalAspect;
      const { width, height } = imgRef.current;
      setCrop(centerAspectCrop(width, height, aspect));
      setAspect(freeCrop ? null : fixedRatio || horizontalAspect);
    },
    [fixedRatio, freeCrop]
  );

  const onCropCompleted = async (crop: Crop) => {
    setProcessing(true);
    croppedImage.current = await makeClientCrop(crop);
    setProcessing(false);
  };

  const makeClientCrop = (crop: Crop): Promise<Blob> => {
    const canvas = document.createElement('canvas');
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX) - 7;
    canvas.height = Math.ceil(crop.height * scaleY) - 7;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    return new Promise((resolve) => {
      setTimeout(() => {
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              return;
            }
            resolve(blob);
          },
          'image/jpeg',
          1
        );
      }, 500);
    });
  };

  useEffect(() => {
    if (!isMobileScreen) {
      document.body.style.position = 'relative';
      return;
    }
    if (open) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'relative';
    }
    return () => {
      document.body.style.position = 'relative';
    };
  }, [open, isMobileScreen]);

  const handleAspectChange = async (newAspect: number) => {
    if (newAspect === aspect) return;
    try {
      croppedImage.current = null;
      const { width, height } = imgRef.current;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      // const newCrop = centerAspectCrop(width, height, newAspect);
      let newCrop: Crop;
      switch (newAspect) {
        case squareAspect:
          newCrop = {
            x: 0,
            y: 0,
            width: Math.min(width, height),
            height: Math.min(width, height),
            unit: 'px',
          };
          break;
        case verticalAspect:
          newCrop = {
            x: 0,
            y: 0,
            width: Math.min(width * verticalAspect, width),
            height: height,
            unit: 'px',
          };
          break;
        case horizontalAspect:
          newCrop = {
            x: 0,
            y: 0,
            width: width,
            height: Math.min(width / horizontalAspect, height),
            unit: 'px',
          };
          break;
      }
      setAspect(newAspect);
      setCrop(newCrop);
      await onCropCompleted(newCrop);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleSubmitCroppedImage = async () => {
    if (!croppedImage.current) {
      croppedImage.current = await makeClientCrop(crop);
    }
    if (croppedImage.current) {
      const imgFile = new File([croppedImage.current], 'image.jpg', {
        type: 'image/jpeg',
      });
      onEditComplete(imgFile);
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={isMobileScreen}
        scroll="paper"
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        {!isMobileScreen ? (
          <div className={styles.dialogWrapper}>
            <div className={styles.content}>
              <div className={styles.head}>画像の確認</div>
              <div className={styles.body}>
                <ReactCrop
                  className={styles.imageContainer}
                  // src={mediaUrl}
                  // onImageLoaded={onLoad}
                  crop={crop}
                  onComplete={onCropCompleted}
                  onChange={(crop) => setCrop(crop)}
                  aspect={aspect}
                  // crossorigin="anonymous"
                  ruleOfThirds
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={mediaUrl}
                    onLoad={onLoad}
                  />
                </ReactCrop>
                {!fixedRatio && !freeCrop && (
                  <div className={styles.aspectBtns}>
                    <div
                      className={cn(styles.aspectBtn, {
                        [styles.active]: aspect === verticalAspect,
                      })}
                      onClick={() => handleAspectChange(verticalAspect)}
                    >
                      縦長
                    </div>
                    <div
                      className={cn(styles.aspectBtn, {
                        [styles.active]: aspect === squareAspect,
                      })}
                      onClick={() => handleAspectChange(squareAspect)}
                    >
                      正方形
                    </div>
                    <div
                      className={cn(styles.aspectBtn, {
                        [styles.active]: aspect === horizontalAspect,
                      })}
                      onClick={() => handleAspectChange(horizontalAspect)}
                    >
                      横長
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.btn}>
                <Button
                  onClick={handleSubmitCroppedImage}
                  disabled={processing}
                  text="OK"
                />
              </div>
            </div>
            <div className={styles.close}>
              <Icon
                name="close-black"
                width={16}
                height={16}
                onClick={handleClose}
              />
            </div>
          </div>
        ) : (
          <div className={styles.dialogWrapper}>
            <div className={styles.header}>
              <button onClick={() => handleClose()} className={styles.cancel}>
                キャンセル
              </button>
              <span>画像の確認</span>
              <div className={styles.submit}>
                <Button
                  onClick={handleSubmitCroppedImage}
                  disabled={processing}
                  text="OK"
                  rounded
                />
              </div>
            </div>
            <div className={styles.content}>
              {!fixedRatio && !freeCrop && (
                <div className={styles.aspectBtns}>
                  <div
                    className={cn(styles.aspectBtn, {
                      [styles.active]: aspect === verticalAspect,
                    })}
                    onClick={() => handleAspectChange(verticalAspect)}
                  >
                    縦長
                  </div>
                  <div
                    className={cn(styles.aspectBtn, {
                      [styles.active]: aspect === squareAspect,
                    })}
                    onClick={() => handleAspectChange(squareAspect)}
                  >
                    正方形
                  </div>
                  <div
                    className={cn(styles.aspectBtn, {
                      [styles.active]: aspect === horizontalAspect,
                    })}
                    onClick={() => handleAspectChange(horizontalAspect)}
                  >
                    横長
                  </div>
                </div>
              )}
              <ReactCrop
                className={styles.imageContainer}
                // src={mediaUrl}
                // onImageLoaded={onLoad}
                crop={crop}
                onComplete={onCropCompleted}
                onChange={(crop) => setCrop(crop)}
                // crossorigin="anonymous"
                ruleOfThirds
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={mediaUrl}
                  onLoad={onLoad}
                />
              </ReactCrop>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
}
